<template>
  <div class="custo-bgparams-group is-multiline" v-if="retObj">
    <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': recap }">
      <div class="columns mb-0">
        <p class="column pb-0">
          Number of turns : <b>{{ retObj.totalWeeks }}</b>
        </p>
        <p class="column pb-0">
          Transparent mode :
          <b>{{ retObj.transparent ? "Enabled" : "Disabled" }}</b>
        </p>
        <p class="column pb-0">
          Timer duration : <b>{{ timerDurationText }}</b>
          <span v-if="retObj.timerStartAuto">
            - Start on
            <b>{{ retObj.timeUnit }} {{ retObj.timerStartAuto }}</b></span
          >
        </p>
      </div>
      <div class="columns mb-0">
        <p class="column pb-0">
          Scenario Product : <b>{{ retObj.product }}</b>
        </p>
        <p class="column pb-0">
          Scenario Currency :
          <b>{{ retObj.currency }}</b>
        </p>
        <p class="column pb-0">
          Scenario Time Unit : <b>{{ retObj.timeUnit }}</b>
        </p>
      </div>
      <div class="columns mb-0">
        <p class="column pb-0">
          Debriefing sections :
          <b>{{
            retObj.debriefContent.length === debriefList.length
              ? "Show the full debriefing"
              : `Show ${retObj.debriefContent.join(", ")}`
          }}</b>
        </p>
      </div>
    </div>
    <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': !recap }">
      <b-field horizontal label="Number of Turns :">
        <p class="control has-text-grey is-italic is-expanded">
          {{ retObj.totalWeeks }} {{ retObj.timeUnit }}s
        </p>
      </b-field>
      <b-field class="px-5">
        <b-slider
          class="mb-6 pt-1"
          v-model.number="retObj.totalWeeks"
          :min="game ? Math.max(game.week, 1) : 1"
          :max="100"
          lazy
          expanded
        >
          <template v-for="val in weeksArray">
            <b-slider-tick :value="val" :key="`w${val}`">{{
              val
            }}</b-slider-tick>
          </template>
        </b-slider>
      </b-field>
      <div class="box-divider"></div>
      <b-field class="mt-4" label="Transparent :" horizontal>
        <b-switch v-model="retObj.transparent">{{
          retObj.transparent
            ? "Players can see each others stock/orders"
            : "Players can't see each other's data"
        }}</b-switch>
      </b-field>
      <b-field class="mt-4" label="Timer :" custom-class="pt-1" horizontal>
        <p class="control pt-2">
          Auto-start timer ?
        </p>
        <b-select v-model="retObj.timerStartAuto">
          <option :value="null">No - manual trigger</option>
          <option v-for="wk in autoTimerWeeks" :key="`atw-${wk}`" :value="wk"
            >On {{ retObj.timeUnit }} {{ wk }}</option
          >
        </b-select>
        <p class="control"></p>
        <p class="control"></p>
        <p class="control pt-2">
          Timer duration :
        </p>
        <b-select v-model="retObj.timer">
          <option
            v-for="option in timerList"
            :value="option.duration"
            :key="option.duration"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <div class="box-divider"></div>
      <b-field label="Product :" custom-class="pt-1" horizontal>
        <b-input
          v-model="retObj.product"
          type="text"
          required
          @keyup.enter.native="sendUpdate"
        ></b-input>
        <p class="control"></p>
        <p class="control has-text-weight-bold pt-2">
          Currency :
        </p>
        <b-select v-model="retObj.currency" placeholder="Currency">
          <option
            v-for="option in ['$', '€', '£']"
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </b-select>
        <p class="control"></p>
        <p class="control has-text-weight-bold pt-2">
          Time Unit :
        </p>
        <b-select :placeholder="retObj.timeUnit" v-model="retObj.timeUnit">
          <option v-for="option in timeUnits" :value="option" :key="option">
            {{ option }}
          </option>
        </b-select>
      </b-field>
      <b-field
        v-if="game ? game.params.debriefContent : true"
        label="Debriefing :"
        horizontal
        class="mt-4"
      >
        <div class="block">
          <b-checkbox
            v-model="retObj.debriefContent"
            v-for="debElem in debriefList"
            v-bind:key="debElem"
            :native-value="debElem"
          >
            {{ debElem }}
          </b-checkbox>
        </div>
      </b-field>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  name: "BgConfigParams",
  computed: {
    timerDurationText() {
      let ret = this.timerList.find(tim => tim.duration === this.retObj.timer);
      return ret ? ret.name : `${this.retObj.timer / 1000} sec`;
    },
    weeksArray() {
      let arr = [];
      let minStart = this.game && this.game.week ? this.game.week + 1 : 1;
      for (let ii = minStart; ii <= 100; ii++) {
        if (ii === minStart || ii / 10 - Math.floor(ii / 10) === 0) {
          arr.push(ii);
        }
      }
      return arr;
    },
    autoTimerWeeks() {
      let arr = [];
      let maxWeeks =
        this.retObj && this.retObj.totalWeeks ? this.retObj.totalWeeks : 10;
      for (let ii = 1; ii <= maxWeeks; ii++) {
        arr.push(ii);
      }
      return arr;
    }
  },
  props: {
    value: Object,
    game: Object,
    recap: Boolean
  },
  created() {
    if (this.value) {
      this.retObj = { ...this.value };
    }
  },
  methods: {
    sendUpdate() {
      this.$emit("sendvalidate");
    }
  },
  watch: {
    game: {
      handler() {
        this.retObj = { ...this.value };
      },
      deep: true
    },
    retObj: {
      handler(newVal) {
        let params = { ...newVal };
        delete params["consumerDemand"];
        delete params["configMessages"];
        this.$emit("input", { ...this.value, ...params });
      },
      deep: true
    }
  },
  data() {
    return {
      retObj: null,
      timeUnits: ["day", "week", "month"],
      debriefList: [
        "Global Results",
        "Fill Rate",
        "Facility Charts",
        "Orders/Stock Charts",
        "Variability",
        "Lead times",
        "To Go Further"
      ],
      timerList: [
        { name: "3 sec (autoplay)", duration: 3000 },
        { name: "30 sec", duration: 30000 },
        { name: "1 min", duration: 60000 },
        { name: "1 min 30 sec", duration: 90000 },
        { name: "2 min", duration: 120000 },
        { name: "2 min 30 sec", duration: 150000 }
      ]
    };
  }
};
</script>

<style lang="scss">
.custo-bgparams-group {
  input[type="number"] {
    min-width: 60px;
  }
}
</style>
