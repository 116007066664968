var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns custo-demand-group is-multiline mb-0"},[_c('div',{staticClass:"column is-half pt-0"},[_c('div',{staticClass:"bg-transit-slide",class:{ 'bg-transit-slide-open': _vm.recap }},[_c('p',[_vm._v(" Demand applied on the "),_c('b',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.retailerName,{ onlyFirstLetter: true })))]),_vm._v(" : ")]),_c('div',{staticClass:"breadcrumb has-dot-separator",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',_vm._l((_vm.value),function(val,ind){return _c('li',{key:ind},[_vm._v(" "+_vm._s(val)+" ")])}),0)])]),_c('div',{staticClass:"bg-transit-slide",class:{ 'bg-transit-slide-open': !_vm.recap }},[_c('b-tabs',{attrs:{"expanded":"","position":"is-centered","animated":false},model:{value:(_vm.demandUpdateTab),callback:function ($$v) {_vm.demandUpdateTab=$$v},expression:"demandUpdateTab"}},[_c('b-tab-item',{attrs:{"label":"Manual Update"}},[_c('p',{staticClass:"mb-2"},[_vm._v(" Modify the values below or simply copy/paste a list of cells from an Excel spreadsheet : ")]),_c('b-field',{attrs:{"type":_vm.manualWarning ? 'is-info' : null,"message":_vm.manualWarning}},[_c('b-input',{attrs:{"type":"textarea","disabled":_vm.disable},on:{"input":_vm.processManualInput},nativeOn:{"keydown":function($event){return _vm.processManuKeyDown($event)}},model:{value:(_vm.manualInput),callback:function ($$v) {_vm.manualInput=$$v},expression:"manualInput"}})],1)],1),_c('b-tab-item',{attrs:{"label":"Generate Profile"}},[_c('b-table',{attrs:{"data":_vm.generateDemand.segments,"mobile-cards":false}},[_c('b-table-column',{attrs:{"label":"From turn"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-field',[_c('b-input',{attrs:{"type":"number","size":"2","min":"1","max":"99","disabled":"","value":props.row.rank === 0
                      ? 1
                      : _vm.generateDemand.segments[props.row.rank - 1].to + 1}})],1)]}}])}),_c('b-table-column',{attrs:{"label":"To turn"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-field',[_c('b-input',{attrs:{"type":"number","size":"2","disabled":_vm.disable ||
                      props.row.rank === _vm.generateDemand.segments.length - 1,"min":props.row.rank === 0
                      ? 1
                      : _vm.generateDemand.segments[props.row.rank - 1].to + 1,"max":props.row.rank === _vm.generateDemand.segments.length - 1
                      ? _vm.totalWeeks
                      : _vm.generateDemand.segments[props.row.rank + 1].to - 1},model:{value:(props.row.to),callback:function ($$v) {_vm.$set(props.row, "to", _vm._n($$v))},expression:"props.row.to"}})],1)]}}])}),_c('b-table-column',{attrs:{"label":"Average"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-field',[_c('b-input',{attrs:{"type":"number","min":"0","max":"999","disabled":_vm.disable},model:{value:(props.row.average),callback:function ($$v) {_vm.$set(props.row, "average", _vm._n($$v))},expression:"props.row.average"}})],1)]}}])}),_c('b-table-column',{attrs:{"label":"Variability"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-field',[_c('b-input',{attrs:{"type":"number","min":"0","max":"999","disabled":_vm.disable},model:{value:(props.row.random),callback:function ($$v) {_vm.$set(props.row, "random", _vm._n($$v))},expression:"props.row.random"}})],1)]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"pt-2"},[(
                    props.row.rank !== 0 &&
                      props.row.rank !== _vm.generateDemand.segments.length - 1
                  )?_c('a',{staticClass:"has-text-weight-bold has-text-danger",on:{"click":function($event){return _vm.removeSegment(props.row.rank)}}},[_vm._v("Remove")]):_vm._e()])]}}])})],1),_c('div',{staticClass:"buttons is-centered mt-2"},[_c('b-button',{attrs:{"type":"is-warning","size":"is-small","icon-left":"refresh","disabled":_vm.disable},on:{"click":_vm.updateDemand}},[_vm._v(" Refresh ")]),_c('b-button',{attrs:{"type":"is-success","size":"is-small","icon-left":"plus","disabled":_vm.disable},on:{"click":_vm.addSegment}},[_vm._v(" Add segment ")])],1)],1)],1)],1)]),_c('div',{staticClass:"column is-half pt-0"},[_c('demand-chart',{ref:"myDemandChart",attrs:{"my-style":_vm.myStyle,"demand":_vm.value,"baseline":_vm.baselineDemand}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }