<template>
  <custom-chart
    ref="chart"
    class="demand-chart"
    v-if="chartdata"
    :chart-data="chartdata"
    :options="options"
    :style="myStyle"
  ></custom-chart>
</template>

<script>
import CustomChart from "@/components/bg/Stats/CustomChart.js";

export default {
  name: "DemandChart",
  props: {
    demand: Array,
    timeUnit: String,
    baseline: Array,
    myStyle: Object
  },
  components: {
    CustomChart
  },
  computed: {
    chartdata() {
      if (!this.demand.length) {
        return false;
      }
      let retObj = {
        labels: this.demand.map((data, index) => {
          return index + 1;
        }),
        datasets: [
          {
            label: "Demand",
            backgroundColor: "transparent",
            borderColor: "#2ec4b6",
            data: this.demand,
            yAxisID: "left-y-axis",
            type: "line"
          },
          {
            backgroundColor: "transparent",
            borderColor: "black",
            lineTension: 0,
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 2,
            data: this.baseline,
            yAxisID: "left-y-axis",
            type: "line"
          }
        ]
      };
      return retObj;
    },
    options() {
      let retObj = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              id: "left-y-axis",
              type: "linear",
              position: "left",
              scaleLabel: {
                display: false,
                labelString: "Units"
              },
              ticks: {
                suggestedMin: 0
              }
            }
          ]
        }
      };
      return retObj;
    }
  }
};
</script>

<style lang="scss">
.demand-chart {
  margin-top: 1em;
  transition: max-height 0.2s ease;
}
</style>
