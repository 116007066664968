<template>
  <div class="custo-messages-group is-multiline">
    <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': recap }">
      <p class="mb-1">
        Players Chat :
        <b>{{ retObj.allowChat ? "Enabled" : "Disabled" }}</b>
      </p>
      <p v-if="retObj.stockMessage.active" class="mb-1">
        If stock higher than
        <b>{{ retObj.stockMessage.moreThan }}pcs</b> :
        <small class="is-italic">"{{ retObj.stockMessage.message }}"</small>
      </p>
      <p v-if="retObj.backlogMessage.active" class="mb-1">
        If backorder higher than
        <b>{{ retObj.backlogMessage.moreThan }}pcs</b> :
        <small class="is-italic">"{{ retObj.backlogMessage.message }}"</small>
      </p>
      <p v-for="(mess, ind) in retObj.scheduleList" :key="ind" class="mb-1">
        On {{ timeUnit }} <b>{{ mess.applyWeek }}</b> to
        <b>{{ toMessTabString(mess.to) }}</b> :
        <small class="is-italic">"{{ mess.message }}"</small>
      </p>
    </div>
    <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': !recap }">
      <b-field>
        <p class="control label mr-4">Players Chat :</p>
        <b-switch v-model="retObj.allowChat" :disabled="disable" class="pb-1">
          {{
            retObj.allowChat
              ? "Participants are allowed to discuss in-game using the chatbox"
              : "Participants can't use the chatbox to discuss in-game"
          }}
        </b-switch>
      </b-field>
      <p class="mb-2 label">
        Conditional Admin messages :
      </p>
      <b-field grouped class="mt-2 mb-1">
        <b-field class="pt-2 mr-0">
          <b-switch v-model="retObj.stockMessage.active" :disabled="disable">
          </b-switch>
        </b-field>
        <p class="control" v-if="!retObj.stockMessage.active">
          <span class="button is-static"
            >No message sent in case of high stock</span
          >
        </p>
        <b-field v-if="retObj.stockMessage.active">
          <p class="control">
            <span class="button is-static">If stock higher than</span>
          </p>
          <b-input
            type="number"
            :min="0"
            :max="999"
            required
            :disabled="disable"
            v-model.number="retObj.stockMessage.moreThan"
          ></b-input>
          <p class="control">
            <span class="button is-static">pcs</span>
          </p>
        </b-field>
        <b-field expanded v-if="retObj.stockMessage.active">
          <p class="control"><span class="button is-static">Send :</span></p>
          <b-input
            expanded
            required
            type="text"
            :disabled="disable"
            v-model.trim="retObj.stockMessage.message"
          ></b-input>
        </b-field>
      </b-field>
      <b-field grouped class="mb-1">
        <b-field class="pt-2 mr-0">
          <b-switch v-model="retObj.backlogMessage.active" :disabled="disable">
          </b-switch>
        </b-field>
        <p class="control" v-if="!retObj.backlogMessage.active">
          <span class="button is-static"
            >No message sent in case of backorders</span
          >
        </p>
        <b-field v-if="retObj.backlogMessage.active">
          <p class="control">
            <span class="button is-static">If backorders higher than</span>
          </p>
          <b-input
            type="number"
            required
            :min="0"
            :max="999"
            :disabled="disable"
            v-model.number="retObj.backlogMessage.moreThan"
          ></b-input>
          <p class="control"><span class="button is-static">pcs</span></p>
        </b-field>
        <b-field expanded v-if="retObj.backlogMessage.active">
          <p class="control"><span class="button is-static">Send :</span></p>
          <b-input
            expanded
            required
            type="text"
            :disabled="disable"
            v-model.trim="retObj.backlogMessage.message"
          ></b-input>
        </b-field>
      </b-field>
      <p class="mb-2 label">
        Scheduled Admin messages :
      </p>
      <b-field
        grouped
        v-for="(mess, ind) in retObj.scheduleList"
        :key="ind"
        class="mb-1"
      >
        <p class="control">
          <b-button
            type="is-danger"
            icon-right="delete"
            @click="removeMess(mess)"
            :disabled="disable"
          ></b-button>
        </p>
        <b-field>
          <p class="control">
            <span class="button is-static">On {{ timeUnit | capitalize }}</span>
          </p>
          <b-input
            type="number"
            required
            :disabled="disable"
            min="0"
            max="200"
            v-model.number="mess.applyWeek"
          ></b-input>
        </b-field>
        <b-field expanded>
          <p class="control">
            <span class="button is-static">Send :</span>
          </p>
          <b-input
            expanded
            :disabled="disable"
            required
            type="text"
            v-model.trim="mess.message"
          ></b-input>
        </b-field>
        <b-field>
          <p class="control">
            <span class="button is-static">To :</span>
          </p>
          <!-- <b-field> -->
          <b-checkbox-button
            :key="'all'"
            v-model="mess.to"
            :disabled="disable"
            native-value="all"
            type="is-primary"
            @input="clickSendTo(mess, 'all')"
          >
            All
          </b-checkbox-button>
          <b-checkbox-button
            v-for="faci in facis"
            :key="faci.id"
            v-model="mess.to"
            :disabled="disable"
            :native-value="faci.id"
            type="is-primary"
            @input="clickSendTo(mess, faci.id)"
          >
            <b-icon
              size="is-small"
              class="pl-1 mr-0"
              :icon="faci.icon"
            ></b-icon>
          </b-checkbox-button>
          <!-- </b-field> -->
        </b-field>
      </b-field>
      <b-field>
        <p class="control">
          <b-button
            type="is-success"
            icon-left="plus"
            @click="addMess()"
            :disabled="disable"
            >Add new scheduled message</b-button
          >
        </p>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "BgConfigMessages",
  props: {
    value: Object,
    timeUnit: String,
    facilities: Object,
    disable: Boolean,
    recap: Boolean,
    game: Object
  },
  computed: {
    facis() {
      return Object.keys(this.facilities).map(id => {
        return {
          id: id,
          icon: this.facilities[id].icon,
          name: this.facilities[id].name
        };
      });
    }
  },
  created() {
    if (this.value) {
      this.retObj = { ...this.value };
    }
  },
  methods: {
    removeMess(mess) {
      const index = this.retObj.scheduleList.indexOf(mess);
      if (index > -1) {
        this.retObj.scheduleList.splice(index, 1);
      }
    },
    addMess() {
      this.retObj.scheduleList.push({
        applyWeek: 1,
        message: "Edit this Message",
        to: ["all"]
      });
    },
    clickSendTo(mess, val) {
      if (val === "all" && mess.to.includes("all")) {
        mess.to = ["all"];
      } else if (val !== "all" && mess.to.includes("all")) {
        mess.to.splice(mess.to.indexOf("all"), 1);
      }
    },
    toMessTabString(toTab) {
      if (
        toTab.length === Object.keys(this.facilities).length ||
        toTab[0] === "all"
      ) {
        return "all";
      }
      return toTab
        .filter(faciId => {
          return this.facilities[faciId];
        })
        .map(faciId => {
          return this.facilities[faciId].name;
        })
        .join(", ");
    }
  },
  watch: {
    game: {
      handler() {
        this.retObj = { ...this.value };
      },
      deep: true
    },
    retObj: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true
    }
  },
  data() {
    return {
      retObj: {
        allowChat: false,
        backlogMessage: {
          sentTo: [],
          active: false,
          message:
            "Watch out, you didn't have enough stock to fulfill demand this week ! The missing quantities will be sent to your client as soon as possible.",
          moreThan: 0
        },
        stockMessage: {
          sentTo: [],
          active: false,
          message:
            "Try to keep a reasonable amount of stock as it impacts your costs !",
          moreThan: 50
        },
        scheduleList: []
      }
    };
  }
};
</script>

<style lang="scss">
.custo-demand-group {
  .columns {
    overflow: hidden;
    @media (max-width: 1024px) {
      // margin: 1.5em 0em;
    }
    // padding: 0.5em 0;
    // padding-bottom: 0.5em;
    &.disabled {
      .label,
      .demand-chart {
        opacity: 0.6;
      }
    }
    .custo-demand-group {
      align-items: start;
    }
  }
  input[type="number"] {
    min-width: 60px;
  }
}
</style>
