<template>
  <div class="columns custo-demand-group is-multiline mb-0">
    <div class="column is-half pt-0">
      <div class="bg-transit-slide" :class="{ 'bg-transit-slide-open': recap }">
        <p>
          Demand applied on the
          <b>{{ retailerName | capitalize({ onlyFirstLetter: true }) }}</b> :
        </p>
        <div class="breadcrumb has-dot-separator" aria-label="breadcrumbs">
          <ul>
            <li v-for="(val, ind) in value" :key="ind">
              {{ val }}
            </li>
          </ul>
        </div>
      </div>
      <div
        class="bg-transit-slide"
        :class="{ 'bg-transit-slide-open': !recap }"
      >
        <b-tabs
          expanded
          position="is-centered"
          :animated="false"
          v-model="demandUpdateTab"
        >
          <b-tab-item label="Manual Update">
            <p class="mb-2">
              Modify the values below or simply copy/paste a list of cells from
              an Excel spreadsheet :
            </p>
            <b-field
              :type="manualWarning ? 'is-info' : null"
              :message="manualWarning"
            >
              <b-input
                type="textarea"
                :disabled="disable"
                @keydown.native="processManuKeyDown"
                v-model="manualInput"
                @input="processManualInput"
              ></b-input>
            </b-field>
          </b-tab-item>
          <b-tab-item label="Generate Profile">
            <b-table :data="generateDemand.segments" :mobile-cards="false">
              <b-table-column v-slot="props" label="From turn">
                <b-field>
                  <b-input
                    type="number"
                    size="2"
                    min="1"
                    max="99"
                    disabled
                    :value="
                      props.row.rank === 0
                        ? 1
                        : generateDemand.segments[props.row.rank - 1].to + 1
                    "
                  ></b-input>
                </b-field>
              </b-table-column>
              <b-table-column v-slot="props" label="To turn">
                <b-field>
                  <b-input
                    type="number"
                    size="2"
                    :disabled="
                      disable ||
                        props.row.rank === generateDemand.segments.length - 1
                    "
                    :min="
                      props.row.rank === 0
                        ? 1
                        : generateDemand.segments[props.row.rank - 1].to + 1
                    "
                    :max="
                      props.row.rank === generateDemand.segments.length - 1
                        ? totalWeeks
                        : generateDemand.segments[props.row.rank + 1].to - 1
                    "
                    v-model.number="props.row.to"
                  ></b-input>
                </b-field>
              </b-table-column>
              <b-table-column v-slot="props" label="Average">
                <b-field>
                  <b-input
                    type="number"
                    min="0"
                    max="999"
                    v-model.number="props.row.average"
                    :disabled="disable"
                  ></b-input>
                </b-field>
              </b-table-column>
              <b-table-column v-slot="props" label="Variability">
                <b-field>
                  <b-input
                    type="number"
                    min="0"
                    max="999"
                    v-model.number="props.row.random"
                    :disabled="disable"
                  ></b-input>
                </b-field>
              </b-table-column>
              <b-table-column v-slot="props">
                <div class="pt-2">
                  <a
                    v-if="
                      props.row.rank !== 0 &&
                        props.row.rank !== generateDemand.segments.length - 1
                    "
                    class="has-text-weight-bold has-text-danger"
                    @click="removeSegment(props.row.rank)"
                    >Remove</a
                  >
                </div>
              </b-table-column>
            </b-table>
            <div class="buttons is-centered mt-2">
              <b-button
                type="is-warning"
                size="is-small"
                icon-left="refresh"
                @click="updateDemand"
                :disabled="disable"
              >
                Refresh
              </b-button>
              <b-button
                type="is-success"
                size="is-small"
                icon-left="plus"
                @click="addSegment"
                :disabled="disable"
              >
                Add segment
              </b-button>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
    <div class="column is-half pt-0">
      <demand-chart
        :my-style="myStyle"
        ref="myDemandChart"
        :demand="value"
        :baseline="baselineDemand"
      ></demand-chart>
    </div>
  </div>
</template>

<script>
import profiles from "@/assets/demandProfiles";
import DemandChart from "@/components/bg/Create/DemandChartContainer.vue";

export default {
  name: "BgConfigDemand",
  props: {
    value: Array,
    totalWeeks: Number,
    myStyle: Object,
    retailerName: String,
    disable: Boolean,
    recap: Boolean
  },
  components: {
    DemandChart
  },
  created() {
    if (this.$route.params && this.$route.params.tab === "create") {
      this.demandUpdateTab = 1;
    }
    this.initializeData();
    setTimeout(() => {
      this.readyWatch = true;
    }, 10);
  },
  methods: {
    initializeData() {
      if (!this.value || !this.value.length) {
        this.updateDemand();
      }
      if (this.value && this.value.length > 0) {
        this.generateDemand.segments[
          this.generateDemand.segments.length - 1
        ].average = this.value[this.value.length - 1];
        this.generateDemand.segments[0].average = this.value[0];
        this.manualInput = this.value.join(",");
      }
      if (this.totalWeeks) {
        this.generateDemand.segments[
          this.generateDemand.segments.length - 1
        ].to = this.totalWeeks;
      }
    },
    processManuKeyDown(ev) {
      if (ev.keyCode == 13) {
        ev.preventDefault();
        this.$emit("sendvalidate");
      }
    },
    processManualInput() {
      this.baselineDemand = [];
      if (this.manualInput[this.manualInput.length - 1] === ",") {
        this.manualInput = this.manualInput.substring(
          0,
          this.manualInput.length - 1
        );
      }
      this.manualInput = this.manualInput.split("\n").join(",");
      this.manualInput = this.manualInput.split("\t").join(",");
      let splitTab = this.manualInput.trim().split(",");
      let newTab = [];
      splitTab.forEach(dem => {
        const parsed = parseInt(dem.replace(/\D/g, ""), 10);
        if (isNaN(parsed)) {
          newTab.push(0);
        } else {
          newTab.push(parsed);
        }
      });
      let gap = newTab.length - this.totalWeeks;
      if (gap > 0) {
        this.manualWarning = `The list is too long vs. the total game duration (${
          this.totalWeeks
        } turns). The last ${gap > 1 ? gap : ""} value${
          gap > 1 ? "s are" : " is"
        } not considered.`;
      } else if (newTab.length < this.totalWeeks) {
        this.manualWarning = `The list isn't long enough vs. the total game duration (${
          this.totalWeeks
        } turns). The demand for the last ${
          gap < -1 ? Math.abs(gap) : ""
        } turn${gap < -1 ? "s" : ""} has been completed with a value of ${
          newTab[newTab.length - 1]
        } pcs.`;
      } else {
        this.manualWarning = null;
      }
      this.emitAdjustedDemand(newTab, this.totalWeeks);
    },
    emitAdjustedDemand(val, totWeeks) {
      let tab = [];
      if (totWeeks <= val.length) {
        tab = [...val].slice(0, totWeeks);
      } else {
        let addArr = new Array(totWeeks - val.length).fill(val[val.length - 1]);
        tab = [...val].concat(addArr);
      }
      this.$emit("input", tab);
      return tab;
    },
    removeSegment(rr) {
      this.generateDemand.segments.splice(rr, 1);
      this.generateDemand.segments.forEach((ss, ind) => {
        ss.rank = ind;
      });
    },
    addSegment() {
      let foundSpace = false;
      let newSegm;
      this.generateDemand.segments.forEach((ss, ind) => {
        if (
          ind > 0 &&
          ss.to > this.generateDemand.segments[ind - 1].to + 1 &&
          !foundSpace
        ) {
          newSegm = JSON.parse(JSON.stringify(ss));
          newSegm.average = Math.round(
            (ss.average + this.generateDemand.segments[ind - 1].average) / 2
          );
          newSegm.to = Math.round(
            (ss.to + this.generateDemand.segments[ind - 1].to) / 2
          );
          foundSpace = true;
          this.generateDemand.segments.splice(ind, 0, newSegm);
        }
      });
      if (foundSpace) {
        this.generateDemand.segments.forEach((ss, ind) => {
          ss.rank = ind;
        });
      }
    },
    updateDemand() {
      let val = profiles.segmentsJoin({
        segments: this.generateDemand.segments,
        totalWeeks: this.totalWeeks
      }).demand;
      this.baselineDemand = profiles.segmentsJoin({
        segments: this.generateDemand.segments,
        totalWeeks: this.totalWeeks
      }).baseline;
      this.manualInput = val.join(",");
      this.manualWarning = null;
      this.$emit("input", val);
    }
  },
  watch: {
    totalWeeks(newVal, oldVal) {
      if (newVal && oldVal) {
        let newTab = this.emitAdjustedDemand(this.value, newVal);
        this.manualWarning = null;
        this.manualInput = newTab.join(",");
        this.generateDemand.segments[
          this.generateDemand.segments.length - 1
        ].to = newVal;
      }
    },
    generateDemand: {
      handler: function() {
        if (this.readyWatch) {
          this.updateDemand();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      demandUpdateTab: 0,
      manualInput: "",
      manualWarning: null,
      readyWatch: false,
      generateDemand: {
        segments: [
          { rank: 0, from: 0, to: 4, average: 4, random: 0 },
          { rank: 1, from: 5, to: 20, average: 8, random: 0 }
        ]
      },
      baselineDemand: []
    };
  }
};
</script>

<style lang="scss">
.custo-demand-group {
  .b-tabs .tab-content {
    padding: 0.5em 0;
  }
  input[type="number"] {
    min-width: 60px;
  }
}
</style>
