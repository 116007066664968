const profiles = {
  flatIncrease(params) {
    // params :
    // {
    //   startDemand: 4,
    //   endDemand: 8,
    //   increaseWeek: 4,
    //   totalWeeks: 40,
    //   startRandom: 3,
    //   endRandom: 6,
    // }
    let customerDemand = { baseline: [], demand: [] };
    for (let ii = 1; ii <= params.totalWeeks; ii++) {
      let randomQty = (Math.round(Math.random()) * 2 - 1) * Math.random();
      if (ii < params.increaseWeek) {
        customerDemand.baseline.push(params.startDemand);
        customerDemand.demand.push(
          Math.max(
            0,
            params.startDemand + Math.round(randomQty * params.startRandom)
          )
        );
      } else {
        customerDemand.baseline.push(params.endDemand);
        customerDemand.demand.push(
          Math.max(
            0,
            params.endDemand + Math.round(randomQty * params.endRandom)
          )
        );
      }
    }
    return customerDemand;
  },
  segmentsJoin(params) {
    // let params = {
    //   segments: [
    //     { from: 0, to: 5, average: 4, random: 2 },
    //     { from: 6, to: 20, average: 8, random: 2 }
    //   ],
    //   totalWeeks: 20
    // };
    let copyParams = JSON.parse(JSON.stringify(params));
    copyParams.segments.forEach((seg, ind) => {
      if (ind === 0) {
        seg.from = 1;
      } else {
        seg.from = copyParams.segments[ind - 1].to + 1;
      }
      if (ind === copyParams.segments.length - 1) {
        seg.to = params.totalWeeks;
      } else {
        seg.to = Math.min(seg.to, copyParams.segments[ind + 1].to - 1);
      }
    });
    let customerDemand = { baseline: [], demand: [] };
    copyParams.segments.forEach(seg => {
      for (let ii = seg.from; ii <= seg.to; ii++) {
        customerDemand.baseline.push(seg.average);
        let randomQty = (Math.round(Math.random()) * 2 - 1) * Math.random();
        customerDemand.demand.push(
          Math.max(
            0,
            seg.average +
              Math.round(randomQty * Math.min(seg.average, seg.random))
          )
        );
      }
    });
    return customerDemand;
  }
};

export default profiles;
