<template>
  <div class="custo-bgfaci-group pt-3" v-if="retObj">
    <div
      class="bg-transit-slide columns mb-0 is-centered is-multiline"
      :class="{ 'bg-transit-slide-open': recap }"
    >
      <div
        class="column is-3 has-text-centered columns is-mobile pt-4 mb-0"
        :key="`faci-${index}`"
        v-for="(faciRank, index) in Object.values(retObj)"
      >
        <div class="column is-hidden-mobile is-paddingless is-2 pt-5">
          <svg
            class="svg-wrap-horizontal"
            width="100%"
            height="20px"
            v-if="index > 0"
          >
            <svg viewBox="0 0 2 2" preserveAspectRatio="xMinYMid">
              <polyline
                fill="#fff"
                stroke-width="0.1"
                stroke="#ff8c89"
                points="1,0 0,1 1,2 "
              />
            </svg>
            <svg viewBox="0 0 1 1" preserveAspectRatio="none">
              <rect x="0" y="0.475" width="1" height="0.05" fill="#ff8c89" />
            </svg>
          </svg>
        </div>
        <div class="column is-paddingless">
          <div class="mb-2">
            <b-icon
              size="is-large"
              type="is-primary"
              :icon="faciRank.icon"
            ></b-icon>
            <h4 class="title is-5">
              {{ faciRank.name | capitalize({ onlyFirstLetter: true }) }}
            </h4>
          </div>
          <p v-if="faciRank.stock">
            Stock : <b>{{ faciRank.stock }}pcs</b>
          </p>
          <p v-if="faciRank.backlog">
            Backorder : <b>{{ faciRank.backlog }}pcs</b>
          </p>
          <p>
            Lead time : <b>{{ faciRank.leadtime }} {{ myParams.timeUnit }}s</b>
          </p>
          <p v-if="faciRank.moq">
            MOQ : <b>{{ faciRank.moq }} pcs</b>
          </p>
          <p v-if="faciRank.batchSize">
            Batch size : <b>{{ faciRank.batchSize }} pcs</b>
          </p>
          <p v-if="faciRank.unitPrice">
            Sale Price :
            <b class="has-text-success">{{
              faciRank.unitPrice | currency(myParams.currency)
            }}</b>
          </p>
          <p v-if="!faciRank.allowBacklog">
            Missed Sale Cost :
            <b class="has-text-danger">{{
              faciRank.missedSaleCost | currency(myParams.currency)
            }}</b>
          </p>
        </div>
      </div>
    </div>
    <div
      class="bg-transit-slide bg-slide-large"
      :class="{ 'bg-transit-slide-open': !recap }"
    >
      <b-tabs
        class="block is-multiline"
        expanded
        position="is-centered"
        :animated="false"
        v-model="selectFaciTab"
      >
        <b-tab-item v-for="faci in myFacis" :key="faci.id" class="pt-0">
          <template #header>
            <span
              class="has-text-centered is-relative"
              :class="{ 'has-text-success': faci.user }"
            >
              <b-icon size="is-small" :icon="faci.icon"></b-icon>
              {{ faci.name | capitalize({ onlyFirstLetter: true }) }}
              <b-button
                type="is-text"
                icon-left="delete"
                class="bg-faci-del-btn has-text-danger"
                @click="removeFaci(faci)"
                v-if="faci.id !== '1' && faci.id !== '400' && !isLive"
              >
              </b-button>
            </span>
          </template>
          <div>
            <div
              v-if="isLive"
              class="lane-user-status has-text-grey has-text-centered py-2"
              :class="{
                'has-background-success-light': faci.user,
                'has-background-light': !faci.user
              }"
            >
              <b-icon
                class="ml-1"
                :icon="faci.user ? 'account' : 'laptop'"
                size="is-small"
              ></b-icon>
              <span v-if="!faci.user">
                No user currently connected
              </span>
              <span v-if="faci.user">
                <b>
                  {{
                    faci.playerName && faci.playerName.length
                      ? faci.playerName
                      : "An anonymous player"
                  }}
                </b>
                is currently connected
              </span>

              <a
                v-if="faci.user"
                class="has-text-weight-bold has-text-danger"
                @click="disconnectUser(faci)"
              >
                <b-icon size="is-small" icon="cancel"></b-icon>
                Disconnect
              </a>
            </div>
            <b-field class="mt-3" horizontal label="Name :">
              <b-input
                v-model="retObj[faci.id].name"
                type="text"
                required
                expanded
                @keyup.enter.native="sendUpdate"
              ></b-input>
              <p class="control"></p>
              <p class="control"></p>
              <p class="control has-text-weight-bold pt-2" v-if="isLive">
                Player Name :
              </p>
              <b-input
                v-if="isLive"
                v-model="retObj[faci.id].playerName"
                type="text"
                @keyup.enter.native="sendUpdate"
              ></b-input>
            </b-field>
            <b-field class="mt-4" horizontal label="Logo :">
              <div class="block">
                <b-radio
                  v-for="ico in iconsList"
                  :key="ico"
                  v-model="retObj[faci.id].icon"
                  :native-value="ico"
                >
                  <b-icon :icon="ico"></b-icon>
                </b-radio>
              </div>
            </b-field>
            <b-field horizontal label="Stock :">
              <b-field grouped>
                <b-input
                  v-model.number="retObj[faci.id].stock"
                  type="number"
                  required
                  style="width:6em;"
                  @input="updateStock($event, faci.id)"
                  @keyup.enter.native="sendUpdate"
                ></b-input>
                <p class="control has-text-grey pt-2"></p>
              </b-field>
              <p class="control"></p>
              <b-field
                grouped
                v-if="(!game || !game.week) && retObj[faci.id].leadtime > 1"
              >
                <p class="control has-text-weight-bold pt-2">
                  Initial orders
                  <b-tooltip
                    multilined
                    style="cursor: help;"
                    type="is-dark"
                    :label="
                      `Initial incoming quantity for the first ${
                        retObj[faci.id].leadtime > 2
                          ? retObj[faci.id].leadtime - 1
                          : ''
                      } week${retObj[faci.id].leadtime > 2 ? 's' : ''}`
                    "
                  >
                    <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                  </b-tooltip>
                  :
                </p>
                <b-input
                  v-model.number="retObj[faci.id].startOrders"
                  type="number"
                  :min="0"
                  :max="999"
                  required
                  @change="$emit('sawleadtimeupdate')"
                  style="width:6em;"
                  @keyup.enter.native="sendUpdate"
                ></b-input>
              </b-field>
              <p class="control"></p>
              <b-field grouped>
                <p class="control has-text-weight-bold pt-2">
                  Backorder :
                </p>
                <b-input
                  v-model.number="retObj[faci.id].backlog"
                  type="number"
                  required
                  style="width:6em;"
                  @input="updateBacklog($event, faci.id)"
                  @keyup.enter.native="sendUpdate"
                ></b-input>
                <p class="control has-text-grey pt-2"></p>
              </b-field>
            </b-field>
            <div class="box-divider"></div>
            <div class="columns mb-0">
              <b-field
                class="column"
                :label="`Stock Cost (${myParams.currency}/pc) :`"
              >
                <b-slider
                  v-model.number="retObj[faci.id].stockCost"
                  :min="0"
                  :max="10"
                  expanded
                  :step="0.5"
                  :ticks="true"
                >
                  <template v-for="val in arrpointfive">
                    <b-slider-tick :value="val" v-bind:key="`l${val}`"
                      >{{ myParams.currency }}{{ val }}</b-slider-tick
                    >
                  </template>
                </b-slider>
              </b-field>
              <b-field class="column" :label="``">
                <template slot="label">
                  Marginal Sale Price ({{ myParams.currency }}/pc)
                  <b-tooltip
                    style="cursor: help;"
                    multilined
                    type="is-dark"
                    position="is-right"
                    label="Adding a sale price will generate Revenue in the game and the reportings"
                  >
                    <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                  </b-tooltip>
                  :
                </template>
                <b-slider
                  v-model.number="retObj[faci.id].unitPrice"
                  :min="0"
                  :max="10"
                  expanded
                  :step="0.5"
                  :ticks="true"
                >
                  <template v-for="val in arrpointfive">
                    <b-slider-tick :value="val" v-bind:key="`l${val}`"
                      >{{ myParams.currency }}{{ val }}</b-slider-tick
                    >
                  </template>
                </b-slider>
              </b-field>
            </div>
            <div class="columns mb-0">
              <b-field class="column mt-3">
                <b-switch v-model="retObj[faci.id].allowBacklog" class="pt-2">{{
                  retObj[faci.id].allowBacklog
                    ? "Allow Backorders"
                    : "No Backorders"
                }}</b-switch>
              </b-field>
              <b-field
                v-if="retObj[faci.id].allowBacklog"
                class="column"
                :label="`Backorder Cost (${myParams.currency}/pc) :`"
              >
                <b-slider
                  v-model.number="retObj[faci.id].backlogCost"
                  :min="0"
                  :max="10"
                  :step="0.5"
                  expanded
                  :ticks="true"
                >
                  <template v-for="val in arrpointfive">
                    <b-slider-tick :value="val" v-bind:key="`l${val}`"
                      >{{ myParams.currency }}{{ val }}</b-slider-tick
                    >
                  </template>
                </b-slider>
              </b-field>
              <b-field
                v-if="!retObj[faci.id].allowBacklog"
                class="column"
                :label="`Missed Sale Cost (${myParams.currency}/pc) :`"
              >
                <b-slider
                  v-model.number="retObj[faci.id].missedSaleCost"
                  :min="0"
                  :max="10"
                  :step="0.5"
                  expanded
                  :ticks="true"
                >
                  <template v-for="val in arrpointfive">
                    <b-slider-tick :value="val" v-bind:key="`l${val}`"
                      >{{ myParams.currency }}{{ val }}</b-slider-tick
                    >
                  </template>
                </b-slider>
              </b-field>
            </div>
            <div class="box-divider"></div>
            <div class="columns mb-0">
              <b-field
                class="column"
                :label="`Leadtime (${myParams.timeUnit}s) :`"
              >
                <b-slider
                  v-model.number="retObj[faci.id].leadtime"
                  :min="1"
                  :max="6"
                  @change="$emit('sawleadtimeupdate')"
                  ticks
                >
                  <template v-for="val in [1, 2, 3, 4, 5, 6]">
                    <b-slider-tick :value="val" :key="`l${val}`">
                      <span>
                        {{ `${val}${myParams.timeUnit.substring(0, 1)}` }}</span
                      ></b-slider-tick
                    >
                  </template>
                </b-slider>
              </b-field>
              <b-field class="column">
                <template slot="label">
                  Visibility limit ({{ myParams.timeUnit }}s)
                  <b-tooltip
                    style="cursor: help;"
                    multilined
                    type="is-dark"
                    position="is-right"
                    label="Time after which players can't see the actual incoming quantites"
                  >
                    <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                  </b-tooltip>
                  :
                </template>
                <b-field>
                  <b-numberinput
                    controls-position="compact"
                    v-model.number="retObj[faci.id].visibleWeeks"
                    :min="0"
                    :max="retObj[faci.id].leadtime"
                    rounded
                  >
                  </b-numberinput>
                </b-field>
              </b-field>
            </div>

            <div class="columns mb-0">
              <b-field class="column" label="Minimum Order Quantity (pcs) :">
                <b-slider
                  v-model.number="retObj[faci.id].moq"
                  :min="0"
                  :max="100"
                >
                  <template v-for="val in arrten">
                    <b-slider-tick :value="val" v-bind:key="`l${val}`">{{
                      val
                    }}</b-slider-tick>
                  </template>
                </b-slider>
              </b-field>
              <b-field class="column">
                <template slot="label">
                  Batch Size (pcs)
                  <b-tooltip
                    style="cursor: help;"
                    multilined
                    type="is-dark"
                    label="Orders must be a multiple of the batch size (0 = no batch)"
                  >
                    <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                  </b-tooltip>
                  :
                </template>
                <b-field>
                  <b-slider
                    v-model.number="retObj[faci.id].batchSize"
                    :min="0"
                    :max="100"
                  >
                    <template v-for="val in arrten">
                      <b-slider-tick :value="val" v-bind:key="`l${val}`">{{
                        val
                      }}</b-slider-tick>
                    </template>
                  </b-slider>
                </b-field>
              </b-field>
            </div>
            <b-field>
              <template slot="label">
                Customize Role Introduction
                <b-tooltip
                  style="cursor: help;"
                  multilined
                  type="is-dark"
                  label="This text will appear at the begining of a game, on the participant onboarding screen."
                >
                  <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                </b-tooltip>
                :
              </template>
              <b-input
                type="textarea"
                rows="2"
                v-model="retObj[faci.id].custoOnboarding"
              ></b-input>
            </b-field>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "BgConfigFaci",
  computed: {
    myParams() {
      if (this.game) {
        return this.game.params;
      } else {
        return this.params;
      }
    },
    myFacis() {
      if (this.game) {
        return Object.values(this.game.facilities) || [];
      } else {
        return Object.values(this.retObj) || [];
      }
    },
    arrten() {
      let tab = [];
      for (let ii = 0; ii <= 100; ii++) {
        if (ii % 10 === 0) {
          tab.push(ii);
        }
      }
      return tab;
    },
    arrpointfive() {
      let tab = [];
      for (let ii = 0; ii <= 10; ii += 1) {
        tab.push(ii);
      }
      return tab;
    },
    rankNewFaci() {
      let minFaciNul = 200;
      Object.values(this.retObj).forEach(faci => {
        let parsedId = parseInt(faci.id, 10);
        if (isNaN(parsedId)) {
          parsedId = 1000;
        }
        if (parsedId < minFaciNul && parsedId > 1) {
          minFaciNul = parsedId;
        }
      });
      minFaciNul = minFaciNul - 1;
      return minFaciNul.toString();
    }
  },
  props: {
    recap: Boolean,
    isLive: Boolean,
    value: Object,
    game: Object,
    params: Object
  },
  created() {
    if (this.value) {
      this.retObj = { ...this.value };
    }
  },
  methods: {
    sendUpdate() {
      this.$emit("sendvalidate");
    },
    disconnectUser(faci) {
      if (!this.game) {
        return;
      }
      this.$store
        .dispatch("quitGame", {
          roleId: faci.id,
          id: this.game.id,
          user_id: faci.user
        })
        .then(() => {
          this.$emit("forceupdate");
        });
    },
    updateStock(qty, faciId) {
      if (qty > 0) {
        this.retObj[faciId].backlog = 0;
      }
    },
    updateBacklog(qty, faciId) {
      if (qty > 0) {
        this.retObj[faciId].stock = 0;
      }
    },
    addFaci() {
      if (this.rankNewFaci && Object.values(this.retObj).length < 8) {
        let copyFacis = JSON.parse(JSON.stringify(this.retObj));
        let randomIcon = this.iconsList[
          Math.floor(Math.random() * this.iconsList.length)
        ];
        let copyBaseRetailer = {
          ...this.retObj["1"],
          type: "warehouse",
          id: this.rankNewFaci,
          icon: randomIcon,
          name: `Stage ${Object.keys(this.retObj).length + 1}`,
          supplier_id: this.retObj["1"].supplier_id,
          client_id: "1"
        };
        copyFacis[this.rankNewFaci] = copyBaseRetailer;
        copyFacis["1"].supplier_id = this.rankNewFaci;
        copyFacis[this.retObj["1"].supplier_id].client_id = this.rankNewFaci;
        this.retObj = copyFacis;
        this.selectFaciTab = 0;
        setTimeout(() => {
          this.selectFaciTab = 1;
        }, 5);
      }
    },
    removeFaci(removedFaci) {
      if (this.isLive) {
        return;
      }
      this.$buefy.dialog.confirm({
        message: `Remove the stage : <b>${removedFaci.name}</b>`,
        cancelText: "Cancel",
        size: "is-medium",
        confirmText: "Remove",
        type: "is-danger",
        onConfirm: () => {
          let copyFacis = {};
          Object.keys(this.retObj).forEach(faciId => {
            if (faciId !== removedFaci.id) {
              copyFacis[faciId] = { ...this.retObj[faciId] };
            }
          });
          copyFacis[removedFaci.client_id].supplier_id =
            removedFaci.supplier_id;
          copyFacis[removedFaci.supplier_id].client_id = removedFaci.client_id;
          this.retObj = copyFacis;
          this.selectFaciTab = 0;
          setTimeout(() => {
            this.selectFaciTab = 1;
          }, 5);
        }
      });
    }
  },
  watch: {
    game: {
      handler() {
        this.retObj = { ...this.value };
      },
      deep: true
    },
    retObj: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true
    }
  },
  data() {
    return {
      selectFaciTab: 0,
      retObj: null,
      iconsList: [
        "cart",
        "call-split",
        "airplane",
        "domain",
        "home",
        "city",
        "briefcase",
        "web",
        "ferry",
        "factory",
        "glass-mug",
        "train",
        "pill",
        "account-box",
        "laptop",
        "mouse",
        "copyright",
        "animation",
        "cog",
        "cellphone",
        "car"
      ]
    };
  }
};
</script>

<style lang="scss">
.custo-bgfaci-group {
  .bg-faci-del-btn {
    position: absolute;
    bottom: -8px;
    left: 100%;
  }
  .tab-content {
    padding-top: 0;
  }
}
</style>
